import { IBreadcrumbsItem } from 'shared/models/breadcrumbs.model';
import { ICategory } from 'shared/models/category.model';
import { UPDATE_BASIS_PAGE_NAMES } from 'constants/middlewares/updateBasisPageNames.const';
import { RouteLocationNormalizedGeneric } from 'vue-router';
import { useUserStore } from 'store/user.store';
import { useInternalStore } from 'shared/store/internalStore.store';
import { CookieManager } from 'shared/utils/cookieManager.util';
import { RequestHelper } from 'shared/utils/requestHelper.util';
import { H3Event } from 'h3';

export class CategoryUtil {
  public static correctBreadcrumbs(breadcrumbs: Array<IBreadcrumbsItem>, ociMode?: boolean, supplierId?: number): Array<IBreadcrumbsItem> {
    let correctedBreadcrumbs = breadcrumbs
      ?.filter((breadcrumb) => (ociMode ? breadcrumb.label !== 'главная' : true))
      ?.map((breadcrumb) => ({
          ...breadcrumb,
          link: ociMode ? `/oci${breadcrumb.link}` : breadcrumb.link,
        }),
      );

    if (supplierId) {
      //TODO сделал как в коде рядом. Потом переписать на енамку или типа того. Сравнивать ВОТ ТАК через лейбл - все же костыль, кмк
      const position = correctedBreadcrumbs.findIndex(({ label }) => label === 'каталог');
      const postfix = `supplier/${supplierId}`;

      const supplierBreadcrumbItem = {
        label: `Поставщик #${supplierId}`,
        link: '/categories/',
      };


      correctedBreadcrumbs.splice(position + 1, 0, supplierBreadcrumbItem);
      correctedBreadcrumbs = correctedBreadcrumbs.map((breadcrumb, idx) => ({
        ...breadcrumb,
        disabledRoute: idx === correctedBreadcrumbs.length - 1,
        link: idx >= position + 1 ? breadcrumb.link + postfix : breadcrumb.link,
      }));
    }

    return correctedBreadcrumbs;
  }

  public static correctCategory(category: ICategory, ociMode?: boolean): ICategory {
    return {
      ...(category || {}),
      slug: ociMode && category?.slug ? `/oci${category?.slug}` : category?.slug,
      subcategory: category?.subcategory?.map?.((subcategory) => CategoryUtil.correctCategory(subcategory, ociMode)),
    };
  }

  public static workWithCatalogCategories(toPath: RouteLocationNormalizedGeneric) {
    return UPDATE_BASIS_PAGE_NAMES.includes(toPath.name?.toString() || '');
  }

  public static isCatalogAvailable(event: H3Event, ociMode: boolean) {
    const runtimeConfig = useRuntimeConfig();
    let isCatalogAvailable;

    if (process.server) {
      const accessToken = CookieManager.getCookieValueByName(
        event?.node?.req.headers.cookie || '',
        runtimeConfig?.public?.accessTokenName || '',
      );
      const refreshToken = CookieManager.getCookieValueByName(
        event?.node?.req.headers.cookie || '',
        runtimeConfig?.public?.refreshTokenName || '',
      );

      const isInternalUser = RequestHelper.hasXInternalNetwork(event?.node?.req?.headers || {});

      isCatalogAvailable = !!(refreshToken && accessToken) || isInternalUser || ociMode;
    }

    if (process.client) {
      const userStore = useUserStore();
      const internalStore = useInternalStore();

      isCatalogAvailable = userStore.isUserLoggedIn || internalStore.getIsInternalUser || ociMode;
    }

    return !!isCatalogAvailable;
  }
}
